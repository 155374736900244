<template>
  <div class="wet-per-crop-overview">
    <div class="my-3" v-html="sanitizedExplanation"></div>
    <table-ui
      :head="riskTable.head"
      :body="riskTable.body"
      :lastIsTotal="true"
      :loading="riskTable.body.length === 0"
      scrolled
      nowrap
      class="mb-4 risk-table"
    >
      <template #ceil-action="{ item, row }">
        <div class="d-flex justify-content-end mx-3 pt-3">
          <button
            v-for="(action, i) in item.text"
            :key="i"
            type="button"
            class="wet-button mr-2"
            @click.prevent="onInsure(row, action.year)"
          >
            {{ action.text }} {{ action.year }}
          </button>
        </div>
      </template>
    </table-ui>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html';
import LogService from '@/services/LogService';
import { mapState } from 'vuex';
import { numToPriceFormat } from '@/common/snippets';
import droughtRiskMixin from '@/mixins/droughtRisk';
import common from '@/mixins/common';

export default {
  name: 'perCropOverview',
  mixins: [common, droughtRiskMixin],
  props: {
    apiData: {
      type: Object,
      default: () => ({}),
    },
    apiYieldHistory: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    tableUi: () => import(/* webpackChunkName: "Table" */ '@/components/ui/tableUi.vue'),
  },
  data() {
    return {
      payoutApi: '/api/risk_assessment/payout_days',
      apiPayoutDays: {},
      threshold: '1',
    };
  },
  computed: {
    ...mapState(['strings', 'lang']),
    ...mapState({
      calendarSettings: (state) => state.settings.calendar,
    }),
    droughtRisk() {
      return this.strings.droughtRisk || {};
    },
    sanitizedExplanation() {
      return sanitizeHtml(this.droughtRisk.drought_risk_results_explanation);
    },
    riskTable() {
      const head = {
        name: { text: this.droughtRisk.table_header_name },
        sum: { text: this.droughtRisk.table_header_amount },
        month: { text: this.droughtRisk.table_header_period },
        action: {},
      };
      const body = (this.apiData.crops || []).map((c) => {
        const month = this.humanizeDateRangeByMonth(c.start, c.end);
        const amount = this.maxAmounts[c.type];
        const action = this.availableInsureYears[c.type].map((year) => ({
          text: this.droughtRisk.table_action,
          year,
        }));
        return {
          name: { text: `${c.hectar} ha ${this.cropsTitles[c.type]}` },
          sum: { text: `${numToPriceFormat(amount, this.lang)} €` },
          month: { text: month },
          action: { text: action },
          id: { text: c.type },
        };
      });
      const datesTotal = []
        .concat(...(this.apiData.crops?.map((c) => [c.start, c.end]) || []))
        .map((d) => new Date(d));
      datesTotal.sort((a, b) => a - b);
      const month = this.humanizeDateRangeByMonth(datesTotal[0], datesTotal.pop());
      if (body.length > 0) {
        const amount = Object.values(this.maxAmounts).reduce((a, c) => {
          let acc = a;
          acc += Math.round(c / 100) * 100;
          return acc;
        }, 0);
        body.push({
          name: { text: this.droughtRisk.table_total },
          sum: {
            text: `${numToPriceFormat(amount, this.lang)} €`,
          },
          month: { text: month },
          action: {},
          id: { text: 'total' },
        });
      }
      return { head, body };
    },
    maxAmounts() {
      return this.calcMaxAmounts();
    },
    /**
     * @returns Object - for each selected crop with array of available years for insure
     */
    availableInsureYears() {
      const availableYears = (s, type) => {
        if (!this.calendarSettings[type]) return [];
        const today = this.$moment().set(this.zeroTime);
        const todayYear = Number(this.droughtRisk.start_year_to_insure);
        const years = [todayYear, todayYear + 1].filter((year) => {
          const start = this.$moment(s, 'YYYY-MM-DD', true)
            .set(this.zeroTime)
            .set({ year });
          const check1 = !start.isBefore(today.clone().add(this.calendarSettings[type].min_contract_start_postponement + 1, 'days'));
          const check2 = !start.isAfter(today.clone().add(this.calendarSettings[type].max_contract_start_postponement, 'days'));
          return check1 && check2;
        });
        return years;
      };
      const availableInsureYears = this.apiData.crops.map((crop) => [
        crop.type,
        availableYears(crop.start, crop.type),
      ]);
      return Object.fromEntries(availableInsureYears);
    },
    crops() {
      return this.strings.drought?.crops || [];
    },
    cropsTitles() {
      return Object.fromEntries(this.crops.map((c) => [c.id, c.title]));
    },
  },
  methods: {
    /**
     * @param {string} start - start date: 'YYYY-MM-DD'
     * @param {string} end - end date: 'YYYY-MM-DD'
     * @returns {string} result string 'January - March 2021'
     */
    humanizeDateRangeByMonth(start, end) {
      const from = this.$moment(start);
      const to = this.$moment(end);
      let month = '';
      if (from.month() === to.month() && from.year() === to.year()) {
        month = from.format('MMMM YYYY');
      } else if (from.year() === to.year()) {
        month = `${from.format('MMMM')} - ${to.format('MMMM YYYY')}`;
      } else {
        month = `${from.format('MMMM YYYY')} - ${to.format('MMMM YYYY')}`;
      }
      return month;
    },
    calcMaxAmounts() {
      LogService.log('calcMaxAmounts, apiPayoutDays', this.apiPayoutDays);
      const amountEntries = Object.entries(this.apiPayoutDays).map(([key, value]) => {
        // calc mean of values
        const values = value[this.threshold];
        const meanDays = this.calcMean(values, 'payout_days');
        LogService.log(`${key} mean days: ${meanDays}`);

        // find years under mean
        const years = values.filter((v) => v.payout_days < meanDays).map((el) => el.year);

        // calc mean of yield
        const mean = this.calcMean(this.apiYieldHistory?.[key] || [], 'yield');

        // use FILTERED yield data by funded years
        const amounts = (this.apiYieldHistory[key] || [])
          .map((el) => ({ /** calc amounts */
            amount: this.calcMedianIndex(
              mean,
              el.yield,
              key,
              'euro',
              this.apiData.crops,
              this.crops,
            ),
            year: el.year,
          }))
          .filter((el) => years.includes(el.year)) /** filter by years */
          .map((el) => Math.round(el.amount / 100) * 100); /** get only amounts */
        return [key, Math.max(...amounts)];
      });
      const maxAmounts = Object.fromEntries(amountEntries);
      this.$emit('maxAmounts', maxAmounts);
      return maxAmounts;
    },
    async getPayoutDaysApi() {
      const url = `${this.payoutApi}?threshold=${this.threshold}`;
      const res = await this.callApi(url);

      this.apiPayoutDays = res.data;
    },
    onInsure(row, year) {
      this.$emit('on-insure', { row, year });
    },
  },
  mounted() {
    this.getPayoutDaysApi();
  },
};
</script>

<style lang="scss">
.risk-table {
  & tbody tr th:not(:nth-child(3)),
  & tbody tr td:not(:nth-child(3)),
  thead {
    font-size: 1.5rem;
  }

  tr td, tr th {
    padding-bottom: 1.5rem;
  }
}
</style>
